<template>
    <div>
        <el-form ref="saveOrEditForm" :model="saveData" style="width:500px" :rules="rules" label-width="160px">
            <el-form-item label="连续SN号" prop="beginSn">
                <el-input v-model="saveData.beginSn" style="width:160px"></el-input> - <el-input v-model="saveData.endSn" style="width:160px"></el-input>
            </el-form-item>
            <el-form-item label="非连续SN号">
                <FileUpload @change="getFile"></FileUpload>
            </el-form-item>
        </el-form>
        <el-row style="padding-left:160px">
            <el-button type="primary" @click="submitForm" :loading="loading">提交</el-button>
            <!-- <el-button type="primary" @click="cancel">取消</el-button> -->
        </el-row>
    </div>
</template>

<script>
    import FileUpload from '@/components/FileUpload'
    import { TerminalApi } from '@/api'
    export default {
        components:{
            FileUpload
        },
        data() {
            return {
                snFileUrl:'',
                saveData:{}
            }
        },
        methods:{
            async submitForm() {
                if(!this.saveData.snFileUrl&&(!this.saveData.beginSn || !this.saveData.endSn)){
                    this.Message.error('请输入连续SN号或者上传SN号文件')
                    return
                } else{
                    let params = {snFileUrl: this.snFileUrl}
                    let result = await  TerminalApi.terminalInfo.snBatchRecover(this.saveData)
                    if(result.success){
                        this.Message.success(result.message)
                        this.$router.push({
                            name:'terminalQuery'
                        })
                    } else{
						this.Message.error(result.message)
                    }

                }

            },
            getFile(file){
                this.saveData.snFileUrl = file
            }
        },

    };
</script>

<style scoped>

</style>
